<!--  -->
<template>
<!-- v-if="active" -->
    <div class="result-outside-box">
        <blur-overlay class="flex-center" zIndex="301" v-if="active">
            <div class="puz-result-box">
                <confirmPanel>
                    <div class='page-subject-result flex-center dia'>
                        <div class="result-body">
                            <div class="result-star-box flex-center">
                                <stars :number="starNum"></stars>
                            </div>
                            <div class="result-item a">
                                
                                <div class="result-status" :class="{'win': starNum > 0, 'lose': starNum <= 0}"></div>
                            </div>
                            <div class="price-box" v-if="awardCount && awardCount > 0">
                                <div class="body">
                                    <div class="p-item a"></div>
                                    <div class="p-item b">
                                        <span class="text-normal-c text flex-center bold">+{{awardCount}}</span>
                                    </div>
                                </div>
                            </div>
                            <div class="foot-box">
                                <div class="result-item b flex-center">
                                    <div class="b-item a">
                                        <img :src="`${PARAMS.assetsUrlV2_0}/subject/result/c5.png`" class="ex-icon right">
                                        <span class="text-normal-aa bold">答对{{correctNum}}题</span>
                                    </div>
                                    <!-- <div class="b-item-line">
                                        <div class="line"></div>
                                    </div> -->
                                    <div class="b-item b">
                                        <img :src="`${PARAMS.assetsUrlV2_0}/subject/result/c6.png`" class="ex-icon wrong">
                                        <span class="text-normal-aa bold">答错{{wrongNum}}题</span>
                                    </div>
                                </div>
                                <div class="result-item c">
                                    <!-- <dia-button type="cancel" shape="square" v-for="(v, i) in funListCul" :key="i" :disabled="v.isDisabled" @click="funHand(v)">
                                        <span class="text-normal">{{v.title}}</span>
                                    </dia-button> -->
                                    <confirmPlay v-for="(v, i) in funListCul" :key="i" :disabled="v.isDisabled" @click="funHand(v)" class="con-item" :type="v.type">
                                        <span class="text-normal">{{v.title}}</span>
                                    </confirmPlay>
                                    <div class="back-box cursor-pointer" @click="back">
                                        <span class="text-normal-ab bold">{{TEXT['subject-result'].t3}}</span>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </confirmPanel>
            </div>
        </blur-overlay>
        <puzzleAnimation ref="puzzleAnimation"></puzzleAnimation>
    </div>
    
    
</template>

<script>
    // import stars from '../components/resultStar.vue'
    import stars from '../components/resultStarBox.vue'
    import apiClass from '@/API/page/subject'
    import puzzleAnimation from './summary/components/puzzleAnimation.vue'

    export default {
        name: '',
        props: {
            puzzleId: Number,
        },
        data() {
            return {
                active: false,
                api: new apiClass(),
                funList: [
                    {title: this.TEXT['subject-result'].t1, disabledCul: 'continueDisabled', fun: 'continue', type: 'success'},
                    {title: this.TEXT['subject-result'].t2, fun: 'restart'},
                    // {title: this.TEXT['subject-result'].t3, fun: 'back'},
                ],
                puzzleInfo: {},
                nextPuzzle: {},
                configList: [],
                puzzleCount: 0,
                nextNoticeState: false,
                animationList: []
            };
        },
        methods: {
            async open(){
                await this.pageInit()
                this.active = true
            },
            close(){
                this.active = false
            },
            async pageInit(){
                await this.configInit()
                await this.puzzleInfoInit()
                // await this.nextPuzzleInit()
                await this.puzzleListInit()
                // this.puzzleResultInit()
            },
            async puzzleListInit(){
                let {listParams} = this
                listParams.page = 0
                listParams.size = 10
                let res = await this.api.getSubjectDirectory(listParams)
                if(!res) return
                this.puzzleCount = res.puzzleCount
            },
            async configInit(){
                let res = await this.api.configLoad('puzzle')
                if(res) this.configList = res
            },
            async puzzleInfoInit(){
                let {usercode: user_code, puzzleId: puzzle_id} = this
                if(!user_code) return
                let res = await this.api.puzzleInfo({puzzle_id, user_code, source: 'result'})
                if(!res) return
                this.puzzleInfo = res
            },
            back(){
                // headVal = headVal || 'G0'
                // puzzleNo = puzzleNo || 1
                // let page = this.getBackPage(puzzleNo)
                // page--
                // if(page < 0) page = 0
                // let params = {page, headVal}
                // params = {routeData: this._common.encodeData(params)}
                this._routeHand.back()
            },
            getBackPage(num){
                let {subjectPageSize: size} = this.PARAMS
                return Math.ceil(num / size)
            },
            eventSet(){
                this.eventOff()
                this._common.eventSet('subjectResultBack', e => {
                    this.back()
                })
            },
            eventOff(){
                this._common.eventRemove('subjectResultBack')
            },
            async puzzleResultInit(){
                let {usercode, puzzleId: puzzle_id} = this
                let res = await this.api.puzzleResult({usercode, puzzle_id})
            },
            funHand(val){
                let {fun} = val || {}
                if(!this[fun]) return
                this[fun](val)
            },
            async nextPuzzleInit(){
                let {
                    configNow: {
                        courseId: course_id
                    },
                    puzzleInfo: {
                        puzzleNo: puzzle_no
                    },
                    usercode: user_code
                } = this
                if(!user_code || !course_id || !puzzle_no) return
                let res = await this.api.nextPuzzle({puzzle_no, user_code, course_id})
                if(!res) return
                res = res || {}
                this.nextPuzzle = res
                return res
            },
            async continue(){
                if(this.continueDisabled) return
                let res = await this.nextPuzzleInit()
                if(!res) return
                await this.animationCheck()
                if(res && (!res.id || !res.puzzleStatus)){
                    this._common.confirmPro.open({
                        message: `暂无更多关卡，敬请期待`,
                        confirmType: 'c3',
                        buttons: [
                            {title: '知道啦', type: 'confirm'}
                        ]
                    })
                    return
                }
                this.continueAction(res)
            },
            continueAction(res){
                let {id, puzzleId} = res || {}
                let rId = puzzleId || id
                let name = 'subject-action'
                let params = {puzzleId: rId}
                params = {
                    routeData: this._common.encodeData(params)
                }
                let type = 'replace'
                this._routeHand.goto({name, params, type})
                this.close()
            },
            async restart(){
                // let {puzzleId} = this

                // let name = 'subject-action'
                // let type = 'replace'
                // let params = {puzzleId}
                // params = {
                //     routeData: this._common.encodeData(params)
                // }
                // let paramsRe ={
                //     routeData: this._common.encodeData({puzzleId: -1})
                // } 
                // this._routeHand.goto({name, paramsRe, type})
                // await this._common.nextTick.call(this)
                // this._routeHand.goto({name, params, type})
                // this.close()
                this.$emit('restart')
                this.close()
            },
            async nextNotice(val){
                await this._common.nextTick.call(this)
                if(!val || this.nextNoticeState) return
                let {courseId} = this.puzzleInfo || {} 
                let {configNow: {value, text}} =  this
                this.nextNoticeState = true
                let {configNow} = this
                if(configNow && configNow.endAnimation){
                    this.$refs['puzzleAnimation'].open({last: true, courseId})
                }else{
                    let msg = text || value
                    this._common.confirmPro.open({
                        confirmType: 'c3',
                        message: `恭喜你，${msg}任务已完成`,
                        buttons: [
                            {title: '知道啦', type: 'confirm'}
                        ]
                    })
                }
                
            },
            async animationCheck(){
                let {puzzleNo} = this.puzzleInfo
                let {courseId} = this.configNow
                let list = await this.animationListInit(courseId)
                if(!list) return 
                let data = this.getCheerData(puzzleNo, list)
                if(!data) return
                let {cheerStatus, cheerId} = data
                if(cheerStatus) return
                this.api.puzzleAnimationClick({cheerId, status: 1})
                await this.$refs['puzzleAnimation'].open({last: false, courseId})
                return true
            },
            async animationListInit(courseId){
                if(!courseId) return
                if(this.animationList.length){
                    let data = this.animationList[0] || {}
                    if(data.courseId == courseId) return this.animationList
                }
                this.animationList = []
                let res = await this.api.puzzleAnimationList({courseId})
                if(res) this.animationList = res
                return res
            },
            getCheerData(no, list){
                list = list || []
                let data = null
                for(let i in list){
                    if(list[i].cheerPos == no){
                        data = list[i]
                        break
                    }
                }
                return data
            }
        },
        created() {
            // this.eventSet()
            // this.pageInit()
        },
        components: {
            stars,
            puzzleAnimation
        },
        computed: {
            listParams(){
                let {app: {usercode: user_code} = {}} = this.$store.state
                let {school_id} = this.PARAMS
                let {
                    configNow: {courseId: course_id}
                } = this
                return {
                    // user_code: '123456',
                    user_code,
                    school_id,
                    course_id,
                }
            },
            nextPuzzleNotice(){
                let {puzzleCount, puzzleInfo: {puzzleNo} = {}, starNum} = this
                if(puzzleCount === null || !puzzleNo) return false
                return puzzleNo == puzzleCount && starNum > 0
            },
            configNow(){
                let {configList: list, puzzleInfo: {courseId: id} = {}} = this
                let data = {}
                for(let i in list){
                    let {resourceDetail: {courseId} = {}} = list[i]
                    if(id == courseId){
                        let tem = list[i] || {}
                        data = tem.resourceDetail
                        break
                    }
                }
                return data
            },
            nextPuzzleLevel(){
                let {configList: list, puzzleInfo: {courseId: id} = {}} = this
                let data = {}
                for(let i in list){
                    let {resourceDetail: {courseId} = {}} = list[i]
                    if(id == courseId){
                        let tem = list[Number(i) + 1] || {}
                        data = tem.resourceDetail
                        break
                    }
                }
                return data
            },
            funListCul(){
                let {funList: list} = this
                let data = this._callFun.funhandAct.call(this, list)
                return data
            },
            continueDisabled(){
                return this.starNum <= 0
            },
            usercode(){
                let {
                    app: {usercode} = {}
                } = this.$store.state
                return usercode
            },
            starNum(){
                let {userStarNum = 0} = this.puzzleInfo || {}
                return userStarNum
            },
            exerciseCount(){
                let {exerciseCount = 0} = this.puzzleInfo || {}
                return exerciseCount
            },
            correctNum(){
                let {correctCount = 0} = this.puzzleInfo || {}
                return correctCount
            },
            wrongNum(){
                let {incorrectCount = 0} = this.puzzleInfo || {}
                return incorrectCount
            },
            puzzleStatus(){
                let {userStarNum = 0} = this.puzzleInfo || {}
                return userStarNum
            },
            awardCount(){
                let {awardCount = 0} = this.puzzleInfo || {}
                return awardCount
            },
        },
        watch: {
            nextPuzzleNotice: {
                handler(val){
                    this.nextNotice(val)
                },
                deep: true,
                immediate: true
            },
            $route: {
                handler(){
                    // this.puzzleId = this.$route.params.puzzleId
                },
                deep: true,
                immediate: true
            },
        },
        mounted() {
        },
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {
            this.eventOff()
        }, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .result-outside-box{
        .com-subject-puzzle-animation{
            z-index: 302;
        }
    }
    
    .page-subject-result.dia{
        // padding-right: @app-padding-side;
        color: #333;
        
        .result-body{
            position: relative;
            // padding: 0px 0px 21px;
            width: 285px;
            height: 307px;
            // background: #FFFEF8;
            // border-radius: 16px;
            .result-star-box{
                position: absolute;
                width: 100%;
                top: 13px;
            }
            .result-item.a{
                position: relative;
                .result-status{
                    // width: 100%;
                    // height: 100px;
                    width: 244px;
                    margin: 0px auto;
                }
                .result-status.win{
                    margin-top: 36px;
                    height: 81px;
                    .background-img-max(url('@{assetsUrlV2_0}/subject/result/c3.png'));
                }
                .result-status.lose{
                    margin-top: 66px;
                    height: 32px;
                    .background-img-max(url('@{assetsUrlV2_0}/subject/result/c4.png'));
                }
                
            }
            .result-item.b{
                position: relative;
                .b-item.a{
                    margin-right: 25px;
                }
                .b-item.b{
                }
                .b-item{
                    display: flex;
                    align-items: center;
                    color: #656060;
                }
                .ex-icon{
                    margin-right: 3px;
                }
                .ex-icon.right{
                    width: 15px;
                    height: 11px;
                }
                .ex-icon.wrong{
                    width: 12px;
                    height: 12px;
                }
                .b-item-line{
                    height: 100%;
                    padding: 3px 0px;
                    position: absolute;
                    width: 2px;
                    height: 100%;
                    top: 0px;
                    left: 0px;
                    right: 0px;
                    bottom: 0px;
                    margin: auto;
                    .line{
                        height: 100%;
                        width: 100%;
                        background: #F5F5F5;
                    }
                }
            }
            .result-item.c{
                margin-top: 13px;
                .com-confirm-button+ .com-confirm-button{
                    margin-top: 13px;
                }
                .com-confirm-button{
                    width: 131px;
                    height: 39px;
                }
                .back-box{
                    height: 19px;
                    color: #656060;
                    text-align: center;
                    width: 65px;
                    margin: 12px auto 0px;
                    .background-img-max(url('@{assetsUrlV2_0}/subject/result/c7.png'));
                    background-size: 100% 2px;
                    background-position: left bottom;

                }
            }


            .foot-box{
                position: absolute;
                bottom: 0px;
                left: 0px;
                width: 100%;
                padding-bottom: 20px;
            }
            .price-box{
                
                position: absolute;
                top: 107px;
                left: 0px;
                width: 100%;
                .body{
                    display: flex;
                    align-items: flex-end;
                    justify-content: space-between;
                    width: 66px;
                    height: 22px;
                    margin: 0px auto;
                    .background-img-max(url('@{assetsUrlV2_0}/subject/result/c8.png'));
                }
                .p-item.a{
                    width: 29px;
                    height: 29px;
                    .background-img-max(url('@{assetsUrlV2_0}/subject/result/c9.png'));
                }
                .p-item.b{
                    height: 100%;
                    display: flex;
                    align-items: center;
                    padding-right: 12px;
                    color: #fff;
                    .text{
                        height: 12px;
                        width: 18px;
                    }
                }
            }
        }
    }
</style>