<!--  -->
<template>
    <div class='com-subject-result-star-box'>
        <!-- <resultStar position="left" class="st-item left"></resultStar>
        <resultStar position="center" class="st-item center"></resultStar>
        <resultStar position="right" class="st-item right"></resultStar> -->
        <div class="star-i-f" v-for="(v, i) in stars" :key="i" >
            <resultStar :position="v.position" class="st-item" :class="[v.class]" :active="v.active"></resultStar>
        </div>
    </div>
</template>

<script>
    import resultStar from './resultStarSingle.vue'

    export default {
        name: '',
        props: {
            number: [Number, String]
        },
        data() {
            return {
                // defaultStar: ['left', 'center', 'right'],
                defaultStar: ['center', 'left', 'right'],
            };
        },
        methods: {

        },
        created() {

        },
        components: {
            resultStar
        },
        computed: {
            stars(){
				let {defaultStar, userStar} = this
				let rd = []
				for(let i in defaultStar){
					let tem = {position: defaultStar[i]}
                    tem.class = defaultStar[i]
                    if((Number(i) + 1) <= userStar) tem.active = true, tem.class += 's'
                    else tem.active = false, tem.class += 'f'
					rd.push(tem)
                }
				return rd
			},
			userStar(){
				let {number} = this
				return number || 0
			},
        },
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .com-subject-result-star-box{
        width: 77px;
        height: 51px;
        position: relative;
        display: flex;
        .st-item{
            position: absolute;
        }
        .st-item.left{
            z-index: 1;
            bottom: 0px;
            left: 0px;
        }
        .st-item.center{
            z-index: 3;
            top: 0px;
            left: 16px;
        }
        .st-item.right{
            z-index: 2;
            bottom: -2px;
            right: 0px;
        }
    }
</style>