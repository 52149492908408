<!--  -->
<template>
    <div class='subject-action'>
        <exercise :sgf="sgf" 
        @gameover="gameover" 
        @update="update"
        @nextExercise="exerciseHand"
        ref="exercise">
            <span slot="title-text" 
            class="text-normal max-width">第{{currentExNum}}/{{exerciseCount}}题</span>

            <div class="ex-hand-fun" slot="extend-fun" v-if="!nextExerciseData && gameResult !== null">
                <!-- <dia-button type="confirm" shape="square" class="flex-center" @click="exerciseHand">
                    <span class="text-normal-a">{{pageText.t1}}</span>
                </dia-button> -->
                <confirmPlay  @click="exerciseHand">
                    <span class="text-normal-a">{{pageText.t1}}</span>
                </confirmPlay>
            </div>
        </exercise>
        <!-- <div class="ex-result-box position-center">
            <puzzleResult :puzzleId.sync="puzzleId"></puzzleResult>
        </div> -->
        <!-- <div class="puz-result-box"> -->
            
            <!-- <blur-overlay class="flex-center" zIndex="300" v-if="">
                <div class="puz-result-box">
                    <confirmPanel>
                        <puzzleResult :puzzleId.sync="puzzleId"></puzzleResult>
                    </confirmPanel>
                </div>
            </blur-overlay> -->
        <!-- </div> -->
        
        <puzzleResult :puzzleId.sync="puzzleId" ref="result" @restart="restart"></puzzleResult>
        
    </div>
</template>

<script>
    import exercise from '@/components/exercise/exercise.vue'
    import apiClass from '@/API/page/subject'
    import puzzleResult from './resultDia.vue'
    import timeCount from '@/components/exercise/timeCount.vue'

    export default {
        name: '',
        props: {},
        extends: timeCount,
        data() {
            return {
                _exercise: null,
                api: new apiClass(),
                routeData: this.$route.params.routeData,
                exerciseList: [],
                puzzleInfo: {},
                exerciseState: false,
                pageState: false,
                gameResult: null,
                // time: 0,
                // intervalData: null
            };
        },
        methods: {
            dataInit(){
                this.exerciseList = []
                this.puzzleInfo = {}
                this.exerciseState = false
                this.pageState = false
                this.gameResult = null
                this.timeCountInit()
            },
            async dataLoad(){
                if(!this.pageState) await this.idReset()
                await this.puzzleInfoInit()
                await this.puzzleListInit()
                this.exerciseValCheck()
                this.pageState = true
            },
            async idReset(){
                let items = {exerciseId: undefined}
                return this.refreshPage({items})
            },
            exerciseComInit(){
                let ele = this.$refs['exercise']
                if(!ele) return
                this._exercise = ele
                this.exerciseState = true
            },
            async puzzleInfoInit(){
                let {usercode: user_code, puzzleId: puzzle_id} = this
                if(!user_code) return
                let res = await this.api.puzzleBasicInfo({puzzle_id, user_code})
                if(!res) return
                this.puzzleInfo = res
            },
            async puzzleListInit(){
                let {puzzleId: puzzle_id} = this
                if(!puzzle_id) return
                let res = await this.api.exerciseList({puzzle_id})
                if(!res) return
                this.exerciseList = res
            },
            exerciseValCheck(){
                let {id: exerciseId} = this.currentExercise || {}
                let {defaultExerciseId: defaultId} = this
                if(exerciseId || !defaultId) return
                let items = {exerciseId: defaultId}
                this.refreshPage({items})
            },
            back(){
                this._routeHand.back()
            },
            getBackPage(num){
                let {subjectPageSize: size} = this.PARAMS
                return Math.ceil(num / size)
            },
            eventSet(){
                this.eventOff()
                this._common.eventSet('subjectActionBack', e => {
                    this.back()
                })
            },
            eventOff(){
                this._common.eventRemove('subjectActionBack')
            },
            refreshPage(opt){
                opt = opt || {}
                let {items, name, type} = opt
                type = type || 'replace'
                return this._common.refreshPage.call(this, {items, name, type})
            },
            setParams(items){
                return this._common.setParams.call(this, items)
            },
            refreshPageAction(opt){
                opt = opt || {}
                let {name, params, type} = opt
                name = name || 'subject-action'
                type = type || 'replace'
                return this._common.refreshPageAction.call(this, {name, params, type})
            },
            async gameover(opt){
                this.gameOverSet = true
                this.timeCountClear()
                let res = await this.answerUpload(opt)
                if(!res) return
                let {
                    nextExerciseData: {id} = {}
                } = this
                // if(id) this._exercise.resultMaskOpen()
                // else this.gameCount()
                this._exercise.resultMaskOpen()

                // await this._common.settimeout(1000)
                // this._exercise.resultMaskOpen()
            },
            gameCount(){
                // let {puzzleId} = this
                // if(!puzzleId) return
                // let name = 'subject-result', type = 'replace', params = {puzzleId}
                // this._routeHand.goto({name, type, params})
                this.$refs['exercise'].stopCountdown()
                this.$refs['result'].open()
            },
            async answerUpload(opt){
                // exerciseId, 
				// userAnswer,
                // correct,
                let {actual: userAnswer, correct, isCorrect} = opt
                let {time: answerDuration} = this
                correct = correct == userAnswer ? 1 : 0
                if(isCorrect !== undefined) correct = isCorrect ? 1 : 0
                let {exerciseId, usercode} = this
                let res = await this.api.uploadUserAnswer({userAnswer, exerciseId, correct, usercode, answerDuration})
                // {user_code,exercise_id,user_answer,correct} = opt
                // let res = await this.api.uploadPuzzle({user_answer: userAnswer, exercise_id: exerciseId, correct, user_code: usercode})
                
                return res
            },
            async nextExercise(){
                let {
                    nextExerciseData: {id: exerciseId} = {}
                } = this
                if(!exerciseId) return
                let items = {exerciseId: undefined}
                await this.refreshPage({items})
                items = {exerciseId}
                await this.refreshPage({items})
            },
            exerciseHand(){
                let {
                    nextExerciseData: {id: exerciseId} = {}
                } = this
                return exerciseId ? this.nextExercise() : this.gameCount()
            },
            update(opt){
                opt = opt || {}
                for(let i in opt){
                    this[i] = opt[i]
                }
            },
            async restart(){
                let data = this.routeDataCul
                let {puzzleId} = this
                let items = {}
                for(let i in data){
                    items[i] = undefined
                }
                items.puzzleId = puzzleId
                this.dataInit()
                await this.refreshPage({items})
                this.dataLoad()
            },
            // timeCountStart(){
            //     this.timeCountClear()
            //     this.time = 0
            //     this.intervalData = setInterval(() => {
            //         this.time++
            //     }, 1000)
            // },
            // timeCountClear(){
            //     if(!this.intervalData) return
            //     clearInterval(this.intervalData)
            // }
        },
        created() {
            this.eventSet()
        },
        mounted() {
            this.exerciseComInit()
        },
        components: {
            exercise,
            puzzleResult,
        },
        computed: {
            sgf(){
                let {
                    currentExercise: {exerciseContent: sgf} = {}
                } = this
                return sgf
            },
            nextExerciseData(){
                let {exerciseListCul: list, currentExercise: curEx = {}} = this
                let rd = null
                for(let i in list){
                    if(list[i].id == curEx.id){
                        let tem = list[Number(i) + 1]
                        rd = tem
                    }
                }
                return rd
            },
            exerciseCount(){
                return this.exerciseListCul.length || 0
            },
            currentExNum(){
                let {exNum = 0} = this.currentExercise || {}
                return exNum
            },
            exerciseListCul(){
                let {exerciseList: list} = this
                list = this._common.deepCopy(list)
                for(let i in list){
                    list[i].exNum = Number(i) + 1
                }
                return list
            },
            defaultExerciseId(){
                let {id} = this.exerciseListCul[0] || {}
                return id
            },
            usercode(){
                let {
                    app: {usercode} = {}
                } = this.$store.state
                return usercode
            },
            routeDataCul(){
                let {routeData} = this
                return this._common.routeDataCul(routeData)
            },
            puzzleId(){
                let {puzzleId} = this.routeDataCul
                return puzzleId
            },
            exerciseId(){
                let {exerciseId} = this.routeDataCul
                return exerciseId
            },
            currentExercise(){
                let {exerciseListCul: list, exerciseId} = this
                let rd = {}
                for(let i in list){
                    let {id} = list[i] || {}
                    if(id == exerciseId){
                        rd = list[i]
                        break
                    }
                }
                return rd
            },
            
        },
        watch: {
            exerciseId: {
                handler(val, oldVal){
                    if(!val || val == oldVal) return
                    this.timeCountStart()
                },
                deep: true,
                immediate: true
            },
            $route: {
                handler(){
                    this.routeData = this.$route.params.routeData
                },
                deep: true,
                immediate: true
            },
            puzzleId: {
                handler(val, oldval){
                    if(!val || val == oldval) return
                    this.dataLoad()
                },
                deep: true,
                immediate: true
                
            }
        },
        
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {
            this.eventOff()
        }, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    .subject-action{
        position: relative;
        .puz-result-box{
            position: relative;
            .re-title{
                position: absolute;
                top: -13px;
                left: 0px;
                width: 100%;
            }
        }
    }
    
</style>