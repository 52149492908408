<!--  -->
<template>
    <div class='com-subject-result-star-single' :class="boxClass">
    </div>
</template>

<script>
    export default {
        name: '',
        props: {
            position: String,
            active: Boolean
        },
        data() {
            return {

            };
        },
        methods: {

        },
        created() {

        },
        components: {
        },
        computed: {
            boxClass(){
                let {position, active} = this
                active = active ? 's' : 'f'
                return `${position} ${active}`
            }
        },
        watch: {},
        mounted() {},
        beforeCreate() {}, //生命周期 - 创建之前
        beforeMount() {}, //生命周期 - 挂载之前
        beforeUpdate() {}, //生命周期 - 更新之前
        updated() {}, //生命周期 - 更新之后
        beforeDestroy() {}, //生命周期 - 销毁之前
        destroyed() {}, //生命周期 - 销毁完成
        activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
    }
</script>
<style lang='less' >
    // .com-subject-result-star-single.left.f, .com-subject-result-star-single.right.f{
    //     width: 31px;
    //     height: 26px;
    //     .background-img-max(url('@{assetsUrlV2_0}/subject/result/c1.png'));
    // }
    // .com-subject-result-star-single.center.f{
    //     width: 37px;
    //     height: 31px;
    //     .background-img-max(url('@{assetsUrlV2_0}/subject/result/c2.png'));
    // }
    // .com-subject-result-star-single.right{
    //     transform: rotateY(180deg);
    // }


    // .com-subject-result-star-single.left.s, .com-subject-result-star-single.right.s{
    //     width: 42px;
    //     height: 35px;
    //     .background-img-max(url('@{assetsUrlV2_0}/subject/result/c1_l.png'));
    // }


    .com-subject-result-star-single.left, .com-subject-result-star-single.right{
        width: 42px;
        height: 35px;
    }
    .com-subject-result-star-single.center{
        width: 49px;
        height: 41px;
    }
    .com-subject-result-star-single.right{
        transform: rotateY(180deg);
    }
    .com-subject-result-star-single.left.f, .com-subject-result-star-single.right.f{
        .background-img-max(url('@{assetsUrlV2_0}/subject/result/f-left.png'));
    }
    .com-subject-result-star-single.center.f{
        .background-img-max(url('@{assetsUrlV2_0}/subject/result/f-center.png'));
    }

    .com-subject-result-star-single.left.s, .com-subject-result-star-single.right.s{
        .background-img-max(url('@{assetsUrlV2_0}/subject/result/s-left.png'));
    }
    .com-subject-result-star-single.center.s{
        .background-img-max(url('@{assetsUrlV2_0}/subject/result/s-center.png'));
    }
</style>